const emojisHex = [
  0x1f600, // Comment to prevent autoformat, this keeps each on each line
  0x1f603, // Comment to prevent autoformat, this keeps each on each line
  0x1f604, // Comment to prevent autoformat, this keeps each on each line
  0x1f601, // Comment to prevent autoformat, this keeps each on each line
  0x1f606, // Comment to prevent autoformat, this keeps each on each line
  0x1f605, // Comment to prevent autoformat, this keeps each on each line
  0x1f923, // Comment to prevent autoformat, this keeps each on each line
  0x1f602, // Comment to prevent autoformat, this keeps each on each line
  0x1f642, // Comment to prevent autoformat, this keeps each on each line
  0x1f643, // Comment to prevent autoformat, this keeps each on each line
  0x1fae0, // Comment to prevent autoformat, this keeps each on each line
  0x1f609, // Comment to prevent autoformat, this keeps each on each line
  0x1f60a, // Comment to prevent autoformat, this keeps each on each line
  0x1f607, // Comment to prevent autoformat, this keeps each on each line
  0x1f970, // Comment to prevent autoformat, this keeps each on each line
  0x1f60d, // Comment to prevent autoformat, this keeps each on each line
  0x1f929, // Comment to prevent autoformat, this keeps each on each line
  0x1f618, // Comment to prevent autoformat, this keeps each on each line
  0x1f617, // Comment to prevent autoformat, this keeps each on each line
  0x1f61a, // Comment to prevent autoformat, this keeps each on each line
  0x1f619, // Comment to prevent autoformat, this keeps each on each line
  0x1f972, // Comment to prevent autoformat, this keeps each on each line
  0x1f60b, // Comment to prevent autoformat, this keeps each on each line
  0x1f61b, // Comment to prevent autoformat, this keeps each on each line
  0x1f61c, // Comment to prevent autoformat, this keeps each on each line
  0x1f92a, // Comment to prevent autoformat, this keeps each on each line
  0x1f61d, // Comment to prevent autoformat, this keeps each on each line
  0x1f911, // Comment to prevent autoformat, this keeps each on each line
  0x1f917, // Comment to prevent autoformat, this keeps each on each line
  0x1f92d, // Comment to prevent autoformat, this keeps each on each line
  0x1fae2, // Comment to prevent autoformat, this keeps each on each line
  0x1fae3, // Comment to prevent autoformat, this keeps each on each line
  0x1f92b, // Comment to prevent autoformat, this keeps each on each line
  0x1f914, // Comment to prevent autoformat, this keeps each on each line
  0x1fae1, // Comment to prevent autoformat, this keeps each on each line
  0x1f910, // Comment to prevent autoformat, this keeps each on each line
  0x1f928, // Comment to prevent autoformat, this keeps each on each line
  0x1f610, // Comment to prevent autoformat, this keeps each on each line
  0x1f611, // Comment to prevent autoformat, this keeps each on each line
  0x1f636, // Comment to prevent autoformat, this keeps each on each line
  0x1fae5, // Comment to prevent autoformat, this keeps each on each line
  0x1f60f, // Comment to prevent autoformat, this keeps each on each line
  0x1f612, // Comment to prevent autoformat, this keeps each on each line
  0x1f644, // Comment to prevent autoformat, this keeps each on each line
  0x1f62c, // Comment to prevent autoformat, this keeps each on each line
  0x1f925, // Comment to prevent autoformat, this keeps each on each line
  0x1f60c, // Comment to prevent autoformat, this keeps each on each line
  0x1f614, // Comment to prevent autoformat, this keeps each on each line
  0x1f62a, // Comment to prevent autoformat, this keeps each on each line
  0x1f924, // Comment to prevent autoformat, this keeps each on each line
  0x1f634, // Comment to prevent autoformat, this keeps each on each line
  0x1f637, // Comment to prevent autoformat, this keeps each on each line
  0x1f912, // Comment to prevent autoformat, this keeps each on each line
  0x1f915, // Comment to prevent autoformat, this keeps each on each line
  0x1f922, // Comment to prevent autoformat, this keeps each on each line
  0x1f92e, // Comment to prevent autoformat, this keeps each on each line
  0x1f927, // Comment to prevent autoformat, this keeps each on each line
  0x1f975, // Comment to prevent autoformat, this keeps each on each line
  0x1f976, // Comment to prevent autoformat, this keeps each on each line
  0x1f974, // Comment to prevent autoformat, this keeps each on each line
  0x1f635, // Comment to prevent autoformat, this keeps each on each line
  0x1f92f, // Comment to prevent autoformat, this keeps each on each line
  0x1f920, // Comment to prevent autoformat, this keeps each on each line
  0x1f973, // Comment to prevent autoformat, this keeps each on each line
  0x1f978, // Comment to prevent autoformat, this keeps each on each line
  0x1f60e, // Comment to prevent autoformat, this keeps each on each line
  0x1f913, // Comment to prevent autoformat, this keeps each on each line
  0x1f9d0, // Comment to prevent autoformat, this keeps each on each line
  0x1f615, // Comment to prevent autoformat, this keeps each on each line
  0x1fae4, // Comment to prevent autoformat, this keeps each on each line
  0x1f61f, // Comment to prevent autoformat, this keeps each on each line
  0x1f641, // Comment to prevent autoformat, this keeps each on each line
  0x1f62e, // Comment to prevent autoformat, this keeps each on each line
  0x1f62f, // Comment to prevent autoformat, this keeps each on each line
  0x1f632, // Comment to prevent autoformat, this keeps each on each line
  0x1f633, // Comment to prevent autoformat, this keeps each on each line
  0x1f97a, // Comment to prevent autoformat, this keeps each on each line
  0x1f979, // Comment to prevent autoformat, this keeps each on each line
  0x1f626, // Comment to prevent autoformat, this keeps each on each line
  0x1f627, // Comment to prevent autoformat, this keeps each on each line
  0x1f628, // Comment to prevent autoformat, this keeps each on each line
  0x1f630, // Comment to prevent autoformat, this keeps each on each line
  0x1f625, // Comment to prevent autoformat, this keeps each on each line
  0x1f622, // Comment to prevent autoformat, this keeps each on each line
  0x1f62d, // Comment to prevent autoformat, this keeps each on each line
  0x1f631, // Comment to prevent autoformat, this keeps each on each line
  0x1f616, // Comment to prevent autoformat, this keeps each on each line
  0x1f623, // Comment to prevent autoformat, this keeps each on each line
  0x1f61e, // Comment to prevent autoformat, this keeps each on each line
  0x1f613, // Comment to prevent autoformat, this keeps each on each line
  0x1f629, // Comment to prevent autoformat, this keeps each on each line
  0x1f62b, // Comment to prevent autoformat, this keeps each on each line
  0x1f971, // Comment to prevent autoformat, this keeps each on each line
  0x1f624, // Comment to prevent autoformat, this keeps each on each line
  0x1f621, // Comment to prevent autoformat, this keeps each on each line
  0x1f620, // Comment to prevent autoformat, this keeps each on each line
  0x1f92c, // Comment to prevent autoformat, this keeps each on each line
  0x1f608, // Comment to prevent autoformat, this keeps each on each line
  0x1f47f, // Comment to prevent autoformat, this keeps each on each line
  0x1f480, // Comment to prevent autoformat, this keeps each on each line
  0x1f4a9, // Comment to prevent autoformat, this keeps each on each line
  0x1f921, // Comment to prevent autoformat, this keeps each on each line
  0x1f479, // Comment to prevent autoformat, this keeps each on each line
  0x1f47a, // Comment to prevent autoformat, this keeps each on each line
  0x1f47b, // Comment to prevent autoformat, this keeps each on each line
  0x1f47d, // Comment to prevent autoformat, this keeps each on each line
  0x1f47e, // Comment to prevent autoformat, this keeps each on each line
  0x1f916, // Comment to prevent autoformat, this keeps each on each line
  0x1f63a, // Comment to prevent autoformat, this keeps each on each line
  0x1f638, // Comment to prevent autoformat, this keeps each on each line
  0x1f639, // Comment to prevent autoformat, this keeps each on each line
  0x1f63b, // Comment to prevent autoformat, this keeps each on each line
  0x1f63c, // Comment to prevent autoformat, this keeps each on each line
  0x1f63d, // Comment to prevent autoformat, this keeps each on each line
  0x1f640, // Comment to prevent autoformat, this keeps each on each line
  0x1f63f, // Comment to prevent autoformat, this keeps each on each line
  0x1f63e, // Comment to prevent autoformat, this keeps each on each line
  0x1f648, // Comment to prevent autoformat, this keeps each on each line
  0x1f649, // Comment to prevent autoformat, this keeps each on each line
  0x1f64a, // Comment to prevent autoformat, this keeps each on each line
  0x1f48c, // Comment to prevent autoformat, this keeps each on each line
  0x1f498, // Comment to prevent autoformat, this keeps each on each line
  0x1f49d, // Comment to prevent autoformat, this keeps each on each line
  0x1f496, // Comment to prevent autoformat, this keeps each on each line
  0x1f497, // Comment to prevent autoformat, this keeps each on each line
  0x1f493, // Comment to prevent autoformat, this keeps each on each line
  0x1f49e, // Comment to prevent autoformat, this keeps each on each line
  0x1f495, // Comment to prevent autoformat, this keeps each on each line
  0x1f49f, // Comment to prevent autoformat, this keeps each on each line
  0x2763, // Comment to prevent autoformat, this keeps each on each line
  0x1f494, // Comment to prevent autoformat, this keeps each on each line
  0x2764, // Comment to prevent autoformat, this keeps each on each line
  0x1f9e1, // Comment to prevent autoformat, this keeps each on each line
  0x1f49b, // Comment to prevent autoformat, this keeps each on each line
  0x1f49a, // Comment to prevent autoformat, this keeps each on each line
  0x1f499, // Comment to prevent autoformat, this keeps each on each line
  0x1f49c, // Comment to prevent autoformat, this keeps each on each line
  0x1f90e, // Comment to prevent autoformat, this keeps each on each line
  0x1f5a4, // Comment to prevent autoformat, this keeps each on each line
  0x1f90d, // Comment to prevent autoformat, this keeps each on each line
  0x1f48b, // Comment to prevent autoformat, this keeps each on each line
  0x1f4af, // Comment to prevent autoformat, this keeps each on each line
  0x1f4a2, // Comment to prevent autoformat, this keeps each on each line
  0x1f4a5, // Comment to prevent autoformat, this keeps each on each line
  0x1f4ab, // Comment to prevent autoformat, this keeps each on each line
  0x1f4a6, // Comment to prevent autoformat, this keeps each on each line
  0x1f4a8, // Comment to prevent autoformat, this keeps each on each line
  0x1f573, // Comment to prevent autoformat, this keeps each on each line
  0x1f4ac, // Comment to prevent autoformat, this keeps each on each line
  0x1f5e8, // Comment to prevent autoformat, this keeps each on each line
  0x1f5ef, // Comment to prevent autoformat, this keeps each on each line
  0x1f4ad, // Comment to prevent autoformat, this keeps each on each line
  0x1f4a4, // Comment to prevent autoformat, this keeps each on each line
  0x1f44b, // Comment to prevent autoformat, this keeps each on each line
  0x1f91a, // Comment to prevent autoformat, this keeps each on each line
  0x1f590, // Comment to prevent autoformat, this keeps each on each line
  0x270b, // Comment to prevent autoformat, this keeps each on each line
  0x1f596, // Comment to prevent autoformat, this keeps each on each line
  0x1faf1, // Comment to prevent autoformat, this keeps each on each line
  0x1faf2, // Comment to prevent autoformat, this keeps each on each line
  0x1faf3, // Comment to prevent autoformat, this keeps each on each line
  0x1faf4, // Comment to prevent autoformat, this keeps each on each line
  0x1f44c, // Comment to prevent autoformat, this keeps each on each line
  0x1f90c, // Comment to prevent autoformat, this keeps each on each line
  0x1f90f, // Comment to prevent autoformat, this keeps each on each line
  0x270c, // Comment to prevent autoformat, this keeps each on each line
  0x1f91e, // Comment to prevent autoformat, this keeps each on each line
  0x1faf0, // Comment to prevent autoformat, this keeps each on each line
  0x1f91f, // Comment to prevent autoformat, this keeps each on each line
  0x1f918, // Comment to prevent autoformat, this keeps each on each line
  0x1f919, // Comment to prevent autoformat, this keeps each on each line
  0x1f448, // Comment to prevent autoformat, this keeps each on each line
  0x1f449, // Comment to prevent autoformat, this keeps each on each line
  0x1f446, // Comment to prevent autoformat, this keeps each on each line
  0x1f595, // Comment to prevent autoformat, this keeps each on each line
  0x1f447, // Comment to prevent autoformat, this keeps each on each line
  0x261d, // Comment to prevent autoformat, this keeps each on each line
  0x1faf5, // Comment to prevent autoformat, this keeps each on each line
  0x1f44d, // Comment to prevent autoformat, this keeps each on each line
  0x1f44e, // Comment to prevent autoformat, this keeps each on each line
  0x270a, // Comment to prevent autoformat, this keeps each on each line
  0x1f44a, // Comment to prevent autoformat, this keeps each on each line
  0x1f91b, // Comment to prevent autoformat, this keeps each on each line
  0x1f91c, // Comment to prevent autoformat, this keeps each on each line
  0x1f44f, // Comment to prevent autoformat, this keeps each on each line
  0x1f64c, // Comment to prevent autoformat, this keeps each on each line
  0x1faf6, // Comment to prevent autoformat, this keeps each on each line
  0x1f450, // Comment to prevent autoformat, this keeps each on each line
  0x1f932, // Comment to prevent autoformat, this keeps each on each line
  0x1f91d, // Comment to prevent autoformat, this keeps each on each line
  0x1f64f, // Comment to prevent autoformat, this keeps each on each line
  0x270d, // Comment to prevent autoformat, this keeps each on each line
  0x1f485, // Comment to prevent autoformat, this keeps each on each line
  0x1f933, // Comment to prevent autoformat, this keeps each on each line
  0x1f4aa, // Comment to prevent autoformat, this keeps each on each line
  0x1f9be, // Comment to prevent autoformat, this keeps each on each line
  0x1f9bf, // Comment to prevent autoformat, this keeps each on each line
  0x1f9b5, // Comment to prevent autoformat, this keeps each on each line
  0x1f9b6, // Comment to prevent autoformat, this keeps each on each line
  0x1f442, // Comment to prevent autoformat, this keeps each on each line
  0x1f9bb, // Comment to prevent autoformat, this keeps each on each line
  0x1f443, // Comment to prevent autoformat, this keeps each on each line
  0x1f9e0, // Comment to prevent autoformat, this keeps each on each line
  0x1fac0, // Comment to prevent autoformat, this keeps each on each line
  0x1fac1, // Comment to prevent autoformat, this keeps each on each line
  0x1f9b7, // Comment to prevent autoformat, this keeps each on each line
  0x1f9b4, // Comment to prevent autoformat, this keeps each on each line
  0x1f440, // Comment to prevent autoformat, this keeps each on each line
  0x1f441, // Comment to prevent autoformat, this keeps each on each line
  0x1f445, // Comment to prevent autoformat, this keeps each on each line
  0x1f444, // Comment to prevent autoformat, this keeps each on each line
  0x1fae6, // Comment to prevent autoformat, this keeps each on each line
  0x1f476, // Comment to prevent autoformat, this keeps each on each line
  0x1f9d2, // Comment to prevent autoformat, this keeps each on each line
  0x1f466, // Comment to prevent autoformat, this keeps each on each line
  0x1f467, // Comment to prevent autoformat, this keeps each on each line
  0x1f9d1, // Comment to prevent autoformat, this keeps each on each line
  0x1f471, // Comment to prevent autoformat, this keeps each on each line
  0x1f468, // Comment to prevent autoformat, this keeps each on each line
  0x1f9d4, // Comment to prevent autoformat, this keeps each on each line
  0x1f469, // Comment to prevent autoformat, this keeps each on each line
  0x1f9d3, // Comment to prevent autoformat, this keeps each on each line
  0x1f474, // Comment to prevent autoformat, this keeps each on each line
  0x1f475, // Comment to prevent autoformat, this keeps each on each line
  0x1f64d, // Comment to prevent autoformat, this keeps each on each line
  0x1f64e, // Comment to prevent autoformat, this keeps each on each line
  0x1f645, // Comment to prevent autoformat, this keeps each on each line
  0x1f646, // Comment to prevent autoformat, this keeps each on each line
  0x1f481, // Comment to prevent autoformat, this keeps each on each line
  0x1f64b, // Comment to prevent autoformat, this keeps each on each line
  0x1f9cf, // Comment to prevent autoformat, this keeps each on each line
  0x1f647, // Comment to prevent autoformat, this keeps each on each line
  0x1f926, // Comment to prevent autoformat, this keeps each on each line
  0x1f937, // Comment to prevent autoformat, this keeps each on each line
  0x1f46e, // Comment to prevent autoformat, this keeps each on each line
  0x1f575, // Comment to prevent autoformat, this keeps each on each line
  0x1f482, // Comment to prevent autoformat, this keeps each on each line
  0x1f977, // Comment to prevent autoformat, this keeps each on each line
  0x1f477, // Comment to prevent autoformat, this keeps each on each line
  0x1fac5, // Comment to prevent autoformat, this keeps each on each line
  0x1f934, // Comment to prevent autoformat, this keeps each on each line
  0x1f478, // Comment to prevent autoformat, this keeps each on each line
  0x1f473, // Comment to prevent autoformat, this keeps each on each line
  0x1f472, // Comment to prevent autoformat, this keeps each on each line
  0x1f9d5, // Comment to prevent autoformat, this keeps each on each line
  0x1f935, // Comment to prevent autoformat, this keeps each on each line
  0x1f470, // Comment to prevent autoformat, this keeps each on each line
  0x1f930, // Comment to prevent autoformat, this keeps each on each line
  0x1fac3, // Comment to prevent autoformat, this keeps each on each line
  0x1fac4, // Comment to prevent autoformat, this keeps each on each line
  0x1f931, // Comment to prevent autoformat, this keeps each on each line
  0x1f47c, // Comment to prevent autoformat, this keeps each on each line
  0x1f385, // Comment to prevent autoformat, this keeps each on each line
  0x1f936, // Comment to prevent autoformat, this keeps each on each line
  0x1f9b8, // Comment to prevent autoformat, this keeps each on each line
  0x1f9b9, // Comment to prevent autoformat, this keeps each on each line
  0x1f9d9, // Comment to prevent autoformat, this keeps each on each line
  0x1f9da, // Comment to prevent autoformat, this keeps each on each line
  0x1f9db, // Comment to prevent autoformat, this keeps each on each line
  0x1f9dc, // Comment to prevent autoformat, this keeps each on each line
  0x1f9dd, // Comment to prevent autoformat, this keeps each on each line
  0x1f9de, // Comment to prevent autoformat, this keeps each on each line
  0x1f9df, // Comment to prevent autoformat, this keeps each on each line
  0x1f9cc, // Comment to prevent autoformat, this keeps each on each line
  0x1f486, // Comment to prevent autoformat, this keeps each on each line
  0x1f487, // Comment to prevent autoformat, this keeps each on each line
  0x1f6b6, // Comment to prevent autoformat, this keeps each on each line
  0x1f9cd, // Comment to prevent autoformat, this keeps each on each line
  0x1f9ce, // Comment to prevent autoformat, this keeps each on each line
  0x1f3c3, // Comment to prevent autoformat, this keeps each on each line
  0x1f483, // Comment to prevent autoformat, this keeps each on each line
  0x1f57a, // Comment to prevent autoformat, this keeps each on each line
  0x1f574, // Comment to prevent autoformat, this keeps each on each line
  0x1f46f, // Comment to prevent autoformat, this keeps each on each line
  0x1f9d6, // Comment to prevent autoformat, this keeps each on each line
  0x1f9d7, // Comment to prevent autoformat, this keeps each on each line
  0x1f93a, // Comment to prevent autoformat, this keeps each on each line
  0x1f3c7, // Comment to prevent autoformat, this keeps each on each line
  0x26f7, // Comment to prevent autoformat, this keeps each on each line
  0x1f3c2, // Comment to prevent autoformat, this keeps each on each line
  0x1f3cc, // Comment to prevent autoformat, this keeps each on each line
  0x1f3c4, // Comment to prevent autoformat, this keeps each on each line
  0x1f6a3, // Comment to prevent autoformat, this keeps each on each line
  0x1f3ca, // Comment to prevent autoformat, this keeps each on each line
  0x26f9, // Comment to prevent autoformat, this keeps each on each line
  0x1f3cb, // Comment to prevent autoformat, this keeps each on each line
  0x1f6b4, // Comment to prevent autoformat, this keeps each on each line
  0x1f6b5, // Comment to prevent autoformat, this keeps each on each line
  0x1f938, // Comment to prevent autoformat, this keeps each on each line
  0x1f93c, // Comment to prevent autoformat, this keeps each on each line
  0x1f93d, // Comment to prevent autoformat, this keeps each on each line
  0x1f93e, // Comment to prevent autoformat, this keeps each on each line
  0x1f939, // Comment to prevent autoformat, this keeps each on each line
  0x1f9d8, // Comment to prevent autoformat, this keeps each on each line
  0x1f6c0, // Comment to prevent autoformat, this keeps each on each line
  0x1f6cc, // Comment to prevent autoformat, this keeps each on each line
  0x1f46d, // Comment to prevent autoformat, this keeps each on each line
  0x1f46b, // Comment to prevent autoformat, this keeps each on each line
  0x1f46c, // Comment to prevent autoformat, this keeps each on each line
  0x1f48f, // Comment to prevent autoformat, this keeps each on each line
  0x1f491, // Comment to prevent autoformat, this keeps each on each line
  0x1f5e3, // Comment to prevent autoformat, this keeps each on each line
  0x1f464, // Comment to prevent autoformat, this keeps each on each line
  0x1f465, // Comment to prevent autoformat, this keeps each on each line
  0x1fac2, // Comment to prevent autoformat, this keeps each on each line
  0x1f46a, // Comment to prevent autoformat, this keeps each on each line
  0x1f463, // Comment to prevent autoformat, this keeps each on each line
  0x1f9b0, // Comment to prevent autoformat, this keeps each on each line
  0x1f9b1, // Comment to prevent autoformat, this keeps each on each line
  0x1f9b3, // Comment to prevent autoformat, this keeps each on each line
  0x1f9b2, // Comment to prevent autoformat, this keeps each on each line
  0x1f435, // Comment to prevent autoformat, this keeps each on each line
  0x1f412, // Comment to prevent autoformat, this keeps each on each line
  0x1f98d, // Comment to prevent autoformat, this keeps each on each line
  0x1f9a7, // Comment to prevent autoformat, this keeps each on each line
  0x1f436, // Comment to prevent autoformat, this keeps each on each line
  0x1f415, // Comment to prevent autoformat, this keeps each on each line
  0x1f9ae, // Comment to prevent autoformat, this keeps each on each line
  0x1f429, // Comment to prevent autoformat, this keeps each on each line
  0x1f43a, // Comment to prevent autoformat, this keeps each on each line
  0x1f98a, // Comment to prevent autoformat, this keeps each on each line
  0x1f99d, // Comment to prevent autoformat, this keeps each on each line
  0x1f431, // Comment to prevent autoformat, this keeps each on each line
  0x1f408, // Comment to prevent autoformat, this keeps each on each line
  0x1f981, // Comment to prevent autoformat, this keeps each on each line
  0x1f42f, // Comment to prevent autoformat, this keeps each on each line
  0x1f405, // Comment to prevent autoformat, this keeps each on each line
  0x1f406, // Comment to prevent autoformat, this keeps each on each line
  0x1f434, // Comment to prevent autoformat, this keeps each on each line
  0x1f40e, // Comment to prevent autoformat, this keeps each on each line
  0x1f984, // Comment to prevent autoformat, this keeps each on each line
  0x1f993, // Comment to prevent autoformat, this keeps each on each line
  0x1f98c, // Comment to prevent autoformat, this keeps each on each line
  0x1f9ac, // Comment to prevent autoformat, this keeps each on each line
  0x1f42e, // Comment to prevent autoformat, this keeps each on each line
  0x1f402, // Comment to prevent autoformat, this keeps each on each line
  0x1f403, // Comment to prevent autoformat, this keeps each on each line
  0x1f404, // Comment to prevent autoformat, this keeps each on each line
  0x1f437, // Comment to prevent autoformat, this keeps each on each line
  0x1f416, // Comment to prevent autoformat, this keeps each on each line
  0x1f417, // Comment to prevent autoformat, this keeps each on each line
  0x1f43d, // Comment to prevent autoformat, this keeps each on each line
  0x1f40f, // Comment to prevent autoformat, this keeps each on each line
  0x1f411, // Comment to prevent autoformat, this keeps each on each line
  0x1f410, // Comment to prevent autoformat, this keeps each on each line
  0x1f42a, // Comment to prevent autoformat, this keeps each on each line
  0x1f42b, // Comment to prevent autoformat, this keeps each on each line
  0x1f999, // Comment to prevent autoformat, this keeps each on each line
  0x1f992, // Comment to prevent autoformat, this keeps each on each line
  0x1f418, // Comment to prevent autoformat, this keeps each on each line
  0x1f9a3, // Comment to prevent autoformat, this keeps each on each line
  0x1f98f, // Comment to prevent autoformat, this keeps each on each line
  0x1f99b, // Comment to prevent autoformat, this keeps each on each line
  0x1f42d, // Comment to prevent autoformat, this keeps each on each line
  0x1f401, // Comment to prevent autoformat, this keeps each on each line
  0x1f400, // Comment to prevent autoformat, this keeps each on each line
  0x1f439, // Comment to prevent autoformat, this keeps each on each line
  0x1f430, // Comment to prevent autoformat, this keeps each on each line
  0x1f407, // Comment to prevent autoformat, this keeps each on each line
  0x1f43f, // Comment to prevent autoformat, this keeps each on each line
  0x1f9ab, // Comment to prevent autoformat, this keeps each on each line
  0x1f994, // Comment to prevent autoformat, this keeps each on each line
  0x1f987, // Comment to prevent autoformat, this keeps each on each line
  0x1f43b, // Comment to prevent autoformat, this keeps each on each line
  0x1f428, // Comment to prevent autoformat, this keeps each on each line
  0x1f43c, // Comment to prevent autoformat, this keeps each on each line
  0x1f9a5, // Comment to prevent autoformat, this keeps each on each line
  0x1f9a6, // Comment to prevent autoformat, this keeps each on each line
  0x1f9a8, // Comment to prevent autoformat, this keeps each on each line
  0x1f998, // Comment to prevent autoformat, this keeps each on each line
  0x1f9a1, // Comment to prevent autoformat, this keeps each on each line
  0x1f43e, // Comment to prevent autoformat, this keeps each on each line
  0x1f983, // Comment to prevent autoformat, this keeps each on each line
  0x1f414, // Comment to prevent autoformat, this keeps each on each line
  0x1f413, // Comment to prevent autoformat, this keeps each on each line
  0x1f423, // Comment to prevent autoformat, this keeps each on each line
  0x1f424, // Comment to prevent autoformat, this keeps each on each line
  0x1f425, // Comment to prevent autoformat, this keeps each on each line
  0x1f426, // Comment to prevent autoformat, this keeps each on each line
  0x1f427, // Comment to prevent autoformat, this keeps each on each line
  0x1f54a, // Comment to prevent autoformat, this keeps each on each line
  0x1f985, // Comment to prevent autoformat, this keeps each on each line
  0x1f986, // Comment to prevent autoformat, this keeps each on each line
  0x1f9a2, // Comment to prevent autoformat, this keeps each on each line
  0x1f989, // Comment to prevent autoformat, this keeps each on each line
  0x1f9a4, // Comment to prevent autoformat, this keeps each on each line
  0x1fab6, // Comment to prevent autoformat, this keeps each on each line
  0x1f9a9, // Comment to prevent autoformat, this keeps each on each line
  0x1f99a, // Comment to prevent autoformat, this keeps each on each line
  0x1f99c, // Comment to prevent autoformat, this keeps each on each line
  0x1f438, // Comment to prevent autoformat, this keeps each on each line
  0x1f40a, // Comment to prevent autoformat, this keeps each on each line
  0x1f422, // Comment to prevent autoformat, this keeps each on each line
  0x1f98e, // Comment to prevent autoformat, this keeps each on each line
  0x1f40d, // Comment to prevent autoformat, this keeps each on each line
  0x1f432, // Comment to prevent autoformat, this keeps each on each line
  0x1f409, // Comment to prevent autoformat, this keeps each on each line
  0x1f995, // Comment to prevent autoformat, this keeps each on each line
  0x1f996, // Comment to prevent autoformat, this keeps each on each line
  0x1f433, // Comment to prevent autoformat, this keeps each on each line
  0x1f40b, // Comment to prevent autoformat, this keeps each on each line
  0x1f42c, // Comment to prevent autoformat, this keeps each on each line
  0x1f9ad, // Comment to prevent autoformat, this keeps each on each line
  0x1f41f, // Comment to prevent autoformat, this keeps each on each line
  0x1f420, // Comment to prevent autoformat, this keeps each on each line
  0x1f421, // Comment to prevent autoformat, this keeps each on each line
  0x1f988, // Comment to prevent autoformat, this keeps each on each line
  0x1f419, // Comment to prevent autoformat, this keeps each on each line
  0x1f41a, // Comment to prevent autoformat, this keeps each on each line
  0x1fab8, // Comment to prevent autoformat, this keeps each on each line
  0x1f40c, // Comment to prevent autoformat, this keeps each on each line
  0x1f98b, // Comment to prevent autoformat, this keeps each on each line
  0x1f41b, // Comment to prevent autoformat, this keeps each on each line
  0x1f41c, // Comment to prevent autoformat, this keeps each on each line
  0x1f41d, // Comment to prevent autoformat, this keeps each on each line
  0x1fab2, // Comment to prevent autoformat, this keeps each on each line
  0x1f41e, // Comment to prevent autoformat, this keeps each on each line
  0x1f997, // Comment to prevent autoformat, this keeps each on each line
  0x1fab3, // Comment to prevent autoformat, this keeps each on each line
  0x1f577, // Comment to prevent autoformat, this keeps each on each line
  0x1f578, // Comment to prevent autoformat, this keeps each on each line
  0x1f982, // Comment to prevent autoformat, this keeps each on each line
  0x1f99f, // Comment to prevent autoformat, this keeps each on each line
  0x1fab0, // Comment to prevent autoformat, this keeps each on each line
  0x1fab1, // Comment to prevent autoformat, this keeps each on each line
  0x1f9a0, // Comment to prevent autoformat, this keeps each on each line
  0x1f490, // Comment to prevent autoformat, this keeps each on each line
  0x1f338, // Comment to prevent autoformat, this keeps each on each line
  0x1f4ae, // Comment to prevent autoformat, this keeps each on each line
  0x1fab7, // Comment to prevent autoformat, this keeps each on each line
  0x1f3f5, // Comment to prevent autoformat, this keeps each on each line
  0x1f339, // Comment to prevent autoformat, this keeps each on each line
  0x1f940, // Comment to prevent autoformat, this keeps each on each line
  0x1f33a, // Comment to prevent autoformat, this keeps each on each line
  0x1f33b, // Comment to prevent autoformat, this keeps each on each line
  0x1f33c, // Comment to prevent autoformat, this keeps each on each line
  0x1f337, // Comment to prevent autoformat, this keeps each on each line
  0x1f331, // Comment to prevent autoformat, this keeps each on each line
  0x1fab4, // Comment to prevent autoformat, this keeps each on each line
  0x1f332, // Comment to prevent autoformat, this keeps each on each line
  0x1f333, // Comment to prevent autoformat, this keeps each on each line
  0x1f334, // Comment to prevent autoformat, this keeps each on each line
  0x1f335, // Comment to prevent autoformat, this keeps each on each line
  0x1f33e, // Comment to prevent autoformat, this keeps each on each line
  0x1f33f, // Comment to prevent autoformat, this keeps each on each line
  0x2618, // Comment to prevent autoformat, this keeps each on each line
  0x1f340, // Comment to prevent autoformat, this keeps each on each line
  0x1f341, // Comment to prevent autoformat, this keeps each on each line
  0x1f342, // Comment to prevent autoformat, this keeps each on each line
  0x1f343, // Comment to prevent autoformat, this keeps each on each line
  0x1fab9, // Comment to prevent autoformat, this keeps each on each line
  0x1faba, // Comment to prevent autoformat, this keeps each on each line
  0x1f344, // Comment to prevent autoformat, this keeps each on each line
  0x1f347, // Comment to prevent autoformat, this keeps each on each line
  0x1f348, // Comment to prevent autoformat, this keeps each on each line
  0x1f349, // Comment to prevent autoformat, this keeps each on each line
  0x1f34a, // Comment to prevent autoformat, this keeps each on each line
  0x1f34b, // Comment to prevent autoformat, this keeps each on each line
  0x1f34c, // Comment to prevent autoformat, this keeps each on each line
  0x1f34d, // Comment to prevent autoformat, this keeps each on each line
  0x1f96d, // Comment to prevent autoformat, this keeps each on each line
  0x1f34e, // Comment to prevent autoformat, this keeps each on each line
  0x1f34f, // Comment to prevent autoformat, this keeps each on each line
  0x1f350, // Comment to prevent autoformat, this keeps each on each line
  0x1f351, // Comment to prevent autoformat, this keeps each on each line
  0x1f352, // Comment to prevent autoformat, this keeps each on each line
  0x1f353, // Comment to prevent autoformat, this keeps each on each line
  0x1fad0, // Comment to prevent autoformat, this keeps each on each line
  0x1f95d, // Comment to prevent autoformat, this keeps each on each line
  0x1f345, // Comment to prevent autoformat, this keeps each on each line
  0x1fad2, // Comment to prevent autoformat, this keeps each on each line
  0x1f965, // Comment to prevent autoformat, this keeps each on each line
  0x1f951, // Comment to prevent autoformat, this keeps each on each line
  0x1f346, // Comment to prevent autoformat, this keeps each on each line
  0x1f954, // Comment to prevent autoformat, this keeps each on each line
  0x1f955, // Comment to prevent autoformat, this keeps each on each line
  0x1f33d, // Comment to prevent autoformat, this keeps each on each line
  0x1f336, // Comment to prevent autoformat, this keeps each on each line
  0x1fad1, // Comment to prevent autoformat, this keeps each on each line
  0x1f952, // Comment to prevent autoformat, this keeps each on each line
  0x1f96c, // Comment to prevent autoformat, this keeps each on each line
  0x1f966, // Comment to prevent autoformat, this keeps each on each line
  0x1f9c4, // Comment to prevent autoformat, this keeps each on each line
  0x1f9c5, // Comment to prevent autoformat, this keeps each on each line
  0x1f95c, // Comment to prevent autoformat, this keeps each on each line
  0x1fad8, // Comment to prevent autoformat, this keeps each on each line
  0x1f330, // Comment to prevent autoformat, this keeps each on each line
  0x1f35e, // Comment to prevent autoformat, this keeps each on each line
  0x1f950, // Comment to prevent autoformat, this keeps each on each line
  0x1f956, // Comment to prevent autoformat, this keeps each on each line
  0x1fad3, // Comment to prevent autoformat, this keeps each on each line
  0x1f968, // Comment to prevent autoformat, this keeps each on each line
  0x1f96f, // Comment to prevent autoformat, this keeps each on each line
  0x1f95e, // Comment to prevent autoformat, this keeps each on each line
  0x1f9c7, // Comment to prevent autoformat, this keeps each on each line
  0x1f9c0, // Comment to prevent autoformat, this keeps each on each line
  0x1f356, // Comment to prevent autoformat, this keeps each on each line
  0x1f357, // Comment to prevent autoformat, this keeps each on each line
  0x1f969, // Comment to prevent autoformat, this keeps each on each line
  0x1f953, // Comment to prevent autoformat, this keeps each on each line
  0x1f354, // Comment to prevent autoformat, this keeps each on each line
  0x1f35f, // Comment to prevent autoformat, this keeps each on each line
  0x1f355, // Comment to prevent autoformat, this keeps each on each line
  0x1f32d, // Comment to prevent autoformat, this keeps each on each line
  0x1f96a, // Comment to prevent autoformat, this keeps each on each line
  0x1f32e, // Comment to prevent autoformat, this keeps each on each line
  0x1f32f, // Comment to prevent autoformat, this keeps each on each line
  0x1fad4, // Comment to prevent autoformat, this keeps each on each line
  0x1f959, // Comment to prevent autoformat, this keeps each on each line
  0x1f9c6, // Comment to prevent autoformat, this keeps each on each line
  0x1f95a, // Comment to prevent autoformat, this keeps each on each line
  0x1f373, // Comment to prevent autoformat, this keeps each on each line
  0x1f958, // Comment to prevent autoformat, this keeps each on each line
  0x1f372, // Comment to prevent autoformat, this keeps each on each line
  0x1fad5, // Comment to prevent autoformat, this keeps each on each line
  0x1f963, // Comment to prevent autoformat, this keeps each on each line
  0x1f957, // Comment to prevent autoformat, this keeps each on each line
  0x1f37f, // Comment to prevent autoformat, this keeps each on each line
  0x1f9c8, // Comment to prevent autoformat, this keeps each on each line
  0x1f9c2, // Comment to prevent autoformat, this keeps each on each line
  0x1f96b, // Comment to prevent autoformat, this keeps each on each line
  0x1f371, // Comment to prevent autoformat, this keeps each on each line
  0x1f358, // Comment to prevent autoformat, this keeps each on each line
  0x1f359, // Comment to prevent autoformat, this keeps each on each line
  0x1f35a, // Comment to prevent autoformat, this keeps each on each line
  0x1f35b, // Comment to prevent autoformat, this keeps each on each line
  0x1f35c, // Comment to prevent autoformat, this keeps each on each line
  0x1f35d, // Comment to prevent autoformat, this keeps each on each line
  0x1f360, // Comment to prevent autoformat, this keeps each on each line
  0x1f362, // Comment to prevent autoformat, this keeps each on each line
  0x1f363, // Comment to prevent autoformat, this keeps each on each line
  0x1f364, // Comment to prevent autoformat, this keeps each on each line
  0x1f365, // Comment to prevent autoformat, this keeps each on each line
  0x1f96e, // Comment to prevent autoformat, this keeps each on each line
  0x1f361, // Comment to prevent autoformat, this keeps each on each line
  0x1f95f, // Comment to prevent autoformat, this keeps each on each line
  0x1f960, // Comment to prevent autoformat, this keeps each on each line
  0x1f961, // Comment to prevent autoformat, this keeps each on each line
  0x1f980, // Comment to prevent autoformat, this keeps each on each line
  0x1f99e, // Comment to prevent autoformat, this keeps each on each line
  0x1f990, // Comment to prevent autoformat, this keeps each on each line
  0x1f991, // Comment to prevent autoformat, this keeps each on each line
  0x1f9aa, // Comment to prevent autoformat, this keeps each on each line
  0x1f366, // Comment to prevent autoformat, this keeps each on each line
  0x1f367, // Comment to prevent autoformat, this keeps each on each line
  0x1f368, // Comment to prevent autoformat, this keeps each on each line
  0x1f369, // Comment to prevent autoformat, this keeps each on each line
  0x1f36a, // Comment to prevent autoformat, this keeps each on each line
  0x1f382, // Comment to prevent autoformat, this keeps each on each line
  0x1f370, // Comment to prevent autoformat, this keeps each on each line
  0x1f9c1, // Comment to prevent autoformat, this keeps each on each line
  0x1f967, // Comment to prevent autoformat, this keeps each on each line
  0x1f36b, // Comment to prevent autoformat, this keeps each on each line
  0x1f36c, // Comment to prevent autoformat, this keeps each on each line
  0x1f36d, // Comment to prevent autoformat, this keeps each on each line
  0x1f36e, // Comment to prevent autoformat, this keeps each on each line
  0x1f36f, // Comment to prevent autoformat, this keeps each on each line
  0x1f37c, // Comment to prevent autoformat, this keeps each on each line
  0x1f95b, // Comment to prevent autoformat, this keeps each on each line
  0x2615, // Comment to prevent autoformat, this keeps each on each line
  0x1fad6, // Comment to prevent autoformat, this keeps each on each line
  0x1f375, // Comment to prevent autoformat, this keeps each on each line
  0x1f376, // Comment to prevent autoformat, this keeps each on each line
  0x1f37e, // Comment to prevent autoformat, this keeps each on each line
  0x1f377, // Comment to prevent autoformat, this keeps each on each line
  0x1f378, // Comment to prevent autoformat, this keeps each on each line
  0x1f379, // Comment to prevent autoformat, this keeps each on each line
  0x1f37a, // Comment to prevent autoformat, this keeps each on each line
  0x1f37b, // Comment to prevent autoformat, this keeps each on each line
  0x1f942, // Comment to prevent autoformat, this keeps each on each line
  0x1f943, // Comment to prevent autoformat, this keeps each on each line
  0x1fad7, // Comment to prevent autoformat, this keeps each on each line
  0x1f964, // Comment to prevent autoformat, this keeps each on each line
  0x1f9cb, // Comment to prevent autoformat, this keeps each on each line
  0x1f9c3, // Comment to prevent autoformat, this keeps each on each line
  0x1f9c9, // Comment to prevent autoformat, this keeps each on each line
  0x1f9ca, // Comment to prevent autoformat, this keeps each on each line
  0x1f962, // Comment to prevent autoformat, this keeps each on each line
  0x1f37d, // Comment to prevent autoformat, this keeps each on each line
  0x1f374, // Comment to prevent autoformat, this keeps each on each line
  0x1f944, // Comment to prevent autoformat, this keeps each on each line
  0x1f52a, // Comment to prevent autoformat, this keeps each on each line
  0x1fad9, // Comment to prevent autoformat, this keeps each on each line
  0x1f3fa, // Comment to prevent autoformat, this keeps each on each line
  0x1f30d, // Comment to prevent autoformat, this keeps each on each line
  0x1f30e, // Comment to prevent autoformat, this keeps each on each line
  0x1f30f, // Comment to prevent autoformat, this keeps each on each line
  0x1f310, // Comment to prevent autoformat, this keeps each on each line
  0x1f5fa, // Comment to prevent autoformat, this keeps each on each line
  0x1f5fe, // Comment to prevent autoformat, this keeps each on each line
  0x1f9ed, // Comment to prevent autoformat, this keeps each on each line
  0x1f3d4, // Comment to prevent autoformat, this keeps each on each line
  0x26f0, // Comment to prevent autoformat, this keeps each on each line
  0x1f30b, // Comment to prevent autoformat, this keeps each on each line
  0x1f5fb, // Comment to prevent autoformat, this keeps each on each line
  0x1f3d5, // Comment to prevent autoformat, this keeps each on each line
  0x1f3d6, // Comment to prevent autoformat, this keeps each on each line
  0x1f3dc, // Comment to prevent autoformat, this keeps each on each line
  0x1f3dd, // Comment to prevent autoformat, this keeps each on each line
  0x1f3de, // Comment to prevent autoformat, this keeps each on each line
  0x1f3df, // Comment to prevent autoformat, this keeps each on each line
  0x1f3db, // Comment to prevent autoformat, this keeps each on each line
  0x1f3d7, // Comment to prevent autoformat, this keeps each on each line
  0x1f9f1, // Comment to prevent autoformat, this keeps each on each line
  0x1faa8, // Comment to prevent autoformat, this keeps each on each line
  0x1fab5, // Comment to prevent autoformat, this keeps each on each line
  0x1f6d6, // Comment to prevent autoformat, this keeps each on each line
  0x1f3d8, // Comment to prevent autoformat, this keeps each on each line
  0x1f3da, // Comment to prevent autoformat, this keeps each on each line
  0x1f3e0, // Comment to prevent autoformat, this keeps each on each line
  0x1f3e1, // Comment to prevent autoformat, this keeps each on each line
  0x1f3e2, // Comment to prevent autoformat, this keeps each on each line
  0x1f3e3, // Comment to prevent autoformat, this keeps each on each line
  0x1f3e4, // Comment to prevent autoformat, this keeps each on each line
  0x1f3e5, // Comment to prevent autoformat, this keeps each on each line
  0x1f3e6, // Comment to prevent autoformat, this keeps each on each line
  0x1f3e8, // Comment to prevent autoformat, this keeps each on each line
  0x1f3e9, // Comment to prevent autoformat, this keeps each on each line
  0x1f3ea, // Comment to prevent autoformat, this keeps each on each line
  0x1f3eb, // Comment to prevent autoformat, this keeps each on each line
  0x1f3ec, // Comment to prevent autoformat, this keeps each on each line
  0x1f3ed, // Comment to prevent autoformat, this keeps each on each line
  0x1f3ef, // Comment to prevent autoformat, this keeps each on each line
  0x1f3f0, // Comment to prevent autoformat, this keeps each on each line
  0x1f492, // Comment to prevent autoformat, this keeps each on each line
  0x1f5fc, // Comment to prevent autoformat, this keeps each on each line
  0x1f5fd, // Comment to prevent autoformat, this keeps each on each line
  0x26ea, // Comment to prevent autoformat, this keeps each on each line
  0x1f54c, // Comment to prevent autoformat, this keeps each on each line
  0x1f6d5, // Comment to prevent autoformat, this keeps each on each line
  0x1f54d, // Comment to prevent autoformat, this keeps each on each line
  0x26e9, // Comment to prevent autoformat, this keeps each on each line
  0x1f54b, // Comment to prevent autoformat, this keeps each on each line
  0x26f2, // Comment to prevent autoformat, this keeps each on each line
  0x26fa, // Comment to prevent autoformat, this keeps each on each line
  0x1f301, // Comment to prevent autoformat, this keeps each on each line
  0x1f303, // Comment to prevent autoformat, this keeps each on each line
  0x1f3d9, // Comment to prevent autoformat, this keeps each on each line
  0x1f304, // Comment to prevent autoformat, this keeps each on each line
  0x1f305, // Comment to prevent autoformat, this keeps each on each line
  0x1f306, // Comment to prevent autoformat, this keeps each on each line
  0x1f307, // Comment to prevent autoformat, this keeps each on each line
  0x1f309, // Comment to prevent autoformat, this keeps each on each line
  0x2668, // Comment to prevent autoformat, this keeps each on each line
  0x1f3a0, // Comment to prevent autoformat, this keeps each on each line
  0x1f6dd, // Comment to prevent autoformat, this keeps each on each line
  0x1f3a1, // Comment to prevent autoformat, this keeps each on each line
  0x1f3a2, // Comment to prevent autoformat, this keeps each on each line
  0x1f488, // Comment to prevent autoformat, this keeps each on each line
  0x1f3aa, // Comment to prevent autoformat, this keeps each on each line
  0x1f682, // Comment to prevent autoformat, this keeps each on each line
  0x1f683, // Comment to prevent autoformat, this keeps each on each line
  0x1f684, // Comment to prevent autoformat, this keeps each on each line
  0x1f685, // Comment to prevent autoformat, this keeps each on each line
  0x1f686, // Comment to prevent autoformat, this keeps each on each line
  0x1f687, // Comment to prevent autoformat, this keeps each on each line
  0x1f688, // Comment to prevent autoformat, this keeps each on each line
  0x1f689, // Comment to prevent autoformat, this keeps each on each line
  0x1f68a, // Comment to prevent autoformat, this keeps each on each line
  0x1f69d, // Comment to prevent autoformat, this keeps each on each line
  0x1f69e, // Comment to prevent autoformat, this keeps each on each line
  0x1f68b, // Comment to prevent autoformat, this keeps each on each line
  0x1f68c, // Comment to prevent autoformat, this keeps each on each line
  0x1f68d, // Comment to prevent autoformat, this keeps each on each line
  0x1f68e, // Comment to prevent autoformat, this keeps each on each line
  0x1f690, // Comment to prevent autoformat, this keeps each on each line
  0x1f691, // Comment to prevent autoformat, this keeps each on each line
  0x1f692, // Comment to prevent autoformat, this keeps each on each line
  0x1f693, // Comment to prevent autoformat, this keeps each on each line
  0x1f694, // Comment to prevent autoformat, this keeps each on each line
  0x1f695, // Comment to prevent autoformat, this keeps each on each line
  0x1f696, // Comment to prevent autoformat, this keeps each on each line
  0x1f697, // Comment to prevent autoformat, this keeps each on each line
  0x1f698, // Comment to prevent autoformat, this keeps each on each line
  0x1f699, // Comment to prevent autoformat, this keeps each on each line
  0x1f6fb, // Comment to prevent autoformat, this keeps each on each line
  0x1f69a, // Comment to prevent autoformat, this keeps each on each line
  0x1f69b, // Comment to prevent autoformat, this keeps each on each line
  0x1f69c, // Comment to prevent autoformat, this keeps each on each line
  0x1f3ce, // Comment to prevent autoformat, this keeps each on each line
  0x1f3cd, // Comment to prevent autoformat, this keeps each on each line
  0x1f6f5, // Comment to prevent autoformat, this keeps each on each line
  0x1f9bd, // Comment to prevent autoformat, this keeps each on each line
  0x1f9bc, // Comment to prevent autoformat, this keeps each on each line
  0x1f6fa, // Comment to prevent autoformat, this keeps each on each line
  0x1f6b2, // Comment to prevent autoformat, this keeps each on each line
  0x1f6f4, // Comment to prevent autoformat, this keeps each on each line
  0x1f6f9, // Comment to prevent autoformat, this keeps each on each line
  0x1f6fc, // Comment to prevent autoformat, this keeps each on each line
  0x1f68f, // Comment to prevent autoformat, this keeps each on each line
  0x1f6e3, // Comment to prevent autoformat, this keeps each on each line
  0x1f6e4, // Comment to prevent autoformat, this keeps each on each line
  0x1f6e2, // Comment to prevent autoformat, this keeps each on each line
  0x26fd, // Comment to prevent autoformat, this keeps each on each line
  0x1f6de, // Comment to prevent autoformat, this keeps each on each line
  0x1f6a8, // Comment to prevent autoformat, this keeps each on each line
  0x1f6a5, // Comment to prevent autoformat, this keeps each on each line
  0x1f6a6, // Comment to prevent autoformat, this keeps each on each line
  0x1f6d1, // Comment to prevent autoformat, this keeps each on each line
  0x1f6a7, // Comment to prevent autoformat, this keeps each on each line
  0x2693, // Comment to prevent autoformat, this keeps each on each line
  0x1f6df, // Comment to prevent autoformat, this keeps each on each line
  0x26f5, // Comment to prevent autoformat, this keeps each on each line
  0x1f6f6, // Comment to prevent autoformat, this keeps each on each line
  0x1f6a4, // Comment to prevent autoformat, this keeps each on each line
  0x1f6f3, // Comment to prevent autoformat, this keeps each on each line
  0x26f4, // Comment to prevent autoformat, this keeps each on each line
  0x1f6e5, // Comment to prevent autoformat, this keeps each on each line
  0x1f6a2, // Comment to prevent autoformat, this keeps each on each line
  0x2708, // Comment to prevent autoformat, this keeps each on each line
  0x1f6e9, // Comment to prevent autoformat, this keeps each on each line
  0x1f6eb, // Comment to prevent autoformat, this keeps each on each line
  0x1f6ec, // Comment to prevent autoformat, this keeps each on each line
  0x1fa82, // Comment to prevent autoformat, this keeps each on each line
  0x1f4ba, // Comment to prevent autoformat, this keeps each on each line
  0x1f681, // Comment to prevent autoformat, this keeps each on each line
  0x1f69f, // Comment to prevent autoformat, this keeps each on each line
  0x1f6a0, // Comment to prevent autoformat, this keeps each on each line
  0x1f6a1, // Comment to prevent autoformat, this keeps each on each line
  0x1f6f0, // Comment to prevent autoformat, this keeps each on each line
  0x1f680, // Comment to prevent autoformat, this keeps each on each line
  0x1f6f8, // Comment to prevent autoformat, this keeps each on each line
  0x1f6ce, // Comment to prevent autoformat, this keeps each on each line
  0x1f9f3, // Comment to prevent autoformat, this keeps each on each line
  0x231b, // Comment to prevent autoformat, this keeps each on each line
  0x23f3, // Comment to prevent autoformat, this keeps each on each line
  0x231a, // Comment to prevent autoformat, this keeps each on each line
  0x23f0, // Comment to prevent autoformat, this keeps each on each line
  0x1f311, // Comment to prevent autoformat, this keeps each on each line
  0x1f315, // Comment to prevent autoformat, this keeps each on each line
  0x1f319, // Comment to prevent autoformat, this keeps each on each line
  0x1f31a, // Comment to prevent autoformat, this keeps each on each line
  0x1f31b, // Comment to prevent autoformat, this keeps each on each line
  0x1f31c, // Comment to prevent autoformat, this keeps each on each line
  0x1f321, // Comment to prevent autoformat, this keeps each on each line
  0x1f31d, // Comment to prevent autoformat, this keeps each on each line
  0x1f31e, // Comment to prevent autoformat, this keeps each on each line
  0x1fa90, // Comment to prevent autoformat, this keeps each on each line
  0x2b50, // Comment to prevent autoformat, this keeps each on each line
  0x1f31f, // Comment to prevent autoformat, this keeps each on each line
  0x1f320, // Comment to prevent autoformat, this keeps each on each line
  0x1f30c, // Comment to prevent autoformat, this keeps each on each line
  0x2601, // Comment to prevent autoformat, this keeps each on each line
  0x26c5, // Comment to prevent autoformat, this keeps each on each line
  0x26c8, // Comment to prevent autoformat, this keeps each on each line
  0x1f324, // Comment to prevent autoformat, this keeps each on each line
  0x1f325, // Comment to prevent autoformat, this keeps each on each line
  0x1f326, // Comment to prevent autoformat, this keeps each on each line
  0x1f327, // Comment to prevent autoformat, this keeps each on each line
  0x1f328, // Comment to prevent autoformat, this keeps each on each line
  0x1f329, // Comment to prevent autoformat, this keeps each on each line
  0x1f32a, // Comment to prevent autoformat, this keeps each on each line
  0x1f32b, // Comment to prevent autoformat, this keeps each on each line
  0x1f32c, // Comment to prevent autoformat, this keeps each on each line
  0x1f300, // Comment to prevent autoformat, this keeps each on each line
  0x1f308, // Comment to prevent autoformat, this keeps each on each line
  0x1f302, // Comment to prevent autoformat, this keeps each on each line
  0x2602, // Comment to prevent autoformat, this keeps each on each line
  0x2614, // Comment to prevent autoformat, this keeps each on each line
  0x26f1, // Comment to prevent autoformat, this keeps each on each line
  0x26a1, // Comment to prevent autoformat, this keeps each on each line
  0x2744, // Comment to prevent autoformat, this keeps each on each line
  0x2603, // Comment to prevent autoformat, this keeps each on each line
  0x26c4, // Comment to prevent autoformat, this keeps each on each line
  0x2604, // Comment to prevent autoformat, this keeps each on each line
  0x1f525, // Comment to prevent autoformat, this keeps each on each line
  0x1f4a7, // Comment to prevent autoformat, this keeps each on each line
  0x1f30a, // Comment to prevent autoformat, this keeps each on each line
  0x1f383, // Comment to prevent autoformat, this keeps each on each line
  0x1f384, // Comment to prevent autoformat, this keeps each on each line
  0x1f386, // Comment to prevent autoformat, this keeps each on each line
  0x1f387, // Comment to prevent autoformat, this keeps each on each line
  0x1f9e8, // Comment to prevent autoformat, this keeps each on each line
  0x2728, // Comment to prevent autoformat, this keeps each on each line
  0x1f388, // Comment to prevent autoformat, this keeps each on each line
  0x1f389, // Comment to prevent autoformat, this keeps each on each line
  0x1f38a, // Comment to prevent autoformat, this keeps each on each line
  0x1f38b, // Comment to prevent autoformat, this keeps each on each line
  0x1f38d, // Comment to prevent autoformat, this keeps each on each line
  0x1f38e, // Comment to prevent autoformat, this keeps each on each line
  0x1f38f, // Comment to prevent autoformat, this keeps each on each line
  0x1f390, // Comment to prevent autoformat, this keeps each on each line
  0x1f391, // Comment to prevent autoformat, this keeps each on each line
  0x1f9e7, // Comment to prevent autoformat, this keeps each on each line
  0x1f380, // Comment to prevent autoformat, this keeps each on each line
  0x1f381, // Comment to prevent autoformat, this keeps each on each line
  0x1f397, // Comment to prevent autoformat, this keeps each on each line
  0x1f39f, // Comment to prevent autoformat, this keeps each on each line
  0x1f3ab, // Comment to prevent autoformat, this keeps each on each line
  0x1f396, // Comment to prevent autoformat, this keeps each on each line
  0x1f3c6, // Comment to prevent autoformat, this keeps each on each line
  0x1f3c5, // Comment to prevent autoformat, this keeps each on each line
  0x1f947, // Comment to prevent autoformat, this keeps each on each line
  0x1f948, // Comment to prevent autoformat, this keeps each on each line
  0x1f949, // Comment to prevent autoformat, this keeps each on each line
  0x26bd, // Comment to prevent autoformat, this keeps each on each line
  0x26be, // Comment to prevent autoformat, this keeps each on each line
  0x1f94e, // Comment to prevent autoformat, this keeps each on each line
  0x1f3c0, // Comment to prevent autoformat, this keeps each on each line
  0x1f3d0, // Comment to prevent autoformat, this keeps each on each line
  0x1f3c8, // Comment to prevent autoformat, this keeps each on each line
  0x1f3c9, // Comment to prevent autoformat, this keeps each on each line
  0x1f3be, // Comment to prevent autoformat, this keeps each on each line
  0x1f94f, // Comment to prevent autoformat, this keeps each on each line
  0x1f3b3, // Comment to prevent autoformat, this keeps each on each line
  0x1f3cf, // Comment to prevent autoformat, this keeps each on each line
  0x1f3d1, // Comment to prevent autoformat, this keeps each on each line
  0x1f3d2, // Comment to prevent autoformat, this keeps each on each line
  0x1f94d, // Comment to prevent autoformat, this keeps each on each line
  0x1f3d3, // Comment to prevent autoformat, this keeps each on each line
  0x1f3f8, // Comment to prevent autoformat, this keeps each on each line
  0x1f94a, // Comment to prevent autoformat, this keeps each on each line
  0x1f94b, // Comment to prevent autoformat, this keeps each on each line
  0x1f945, // Comment to prevent autoformat, this keeps each on each line
  0x26f3, // Comment to prevent autoformat, this keeps each on each line
  0x26f8, // Comment to prevent autoformat, this keeps each on each line
  0x1f3a3, // Comment to prevent autoformat, this keeps each on each line
  0x1f93f, // Comment to prevent autoformat, this keeps each on each line
  0x1f3bd, // Comment to prevent autoformat, this keeps each on each line
  0x1f3bf, // Comment to prevent autoformat, this keeps each on each line
  0x1f6f7, // Comment to prevent autoformat, this keeps each on each line
  0x1f94c, // Comment to prevent autoformat, this keeps each on each line
  0x1f3af, // Comment to prevent autoformat, this keeps each on each line
  0x1fa80, // Comment to prevent autoformat, this keeps each on each line
  0x1fa81, // Comment to prevent autoformat, this keeps each on each line
  0x1f52b, // Comment to prevent autoformat, this keeps each on each line
  0x1f3b1, // Comment to prevent autoformat, this keeps each on each line
  0x1f52e, // Comment to prevent autoformat, this keeps each on each line
  0x1fa84, // Comment to prevent autoformat, this keeps each on each line
  0x1f3ae, // Comment to prevent autoformat, this keeps each on each line
  0x1f579, // Comment to prevent autoformat, this keeps each on each line
  0x1f3b0, // Comment to prevent autoformat, this keeps each on each line
  0x1f3b2, // Comment to prevent autoformat, this keeps each on each line
  0x1f9e9, // Comment to prevent autoformat, this keeps each on each line
  0x1f9f8, // Comment to prevent autoformat, this keeps each on each line
  0x1fa85, // Comment to prevent autoformat, this keeps each on each line
  0x1faa9, // Comment to prevent autoformat, this keeps each on each line
  0x1fa86, // Comment to prevent autoformat, this keeps each on each line
  0x2660, // Comment to prevent autoformat, this keeps each on each line
  0x2665, // Comment to prevent autoformat, this keeps each on each line
  0x2666, // Comment to prevent autoformat, this keeps each on each line
  0x2663, // Comment to prevent autoformat, this keeps each on each line
  0x265f, // Comment to prevent autoformat, this keeps each on each line
  0x1f0cf, // Comment to prevent autoformat, this keeps each on each line
  0x1f004, // Comment to prevent autoformat, this keeps each on each line
  0x1f3b4, // Comment to prevent autoformat, this keeps each on each line
  0x1f3ad, // Comment to prevent autoformat, this keeps each on each line
  0x1f5bc, // Comment to prevent autoformat, this keeps each on each line
  0x1f3a8, // Comment to prevent autoformat, this keeps each on each line
  0x1f9f5, // Comment to prevent autoformat, this keeps each on each line
  0x1faa1, // Comment to prevent autoformat, this keeps each on each line
  0x1f9f6, // Comment to prevent autoformat, this keeps each on each line
  0x1faa2, // Comment to prevent autoformat, this keeps each on each line
  0x1f453, // Comment to prevent autoformat, this keeps each on each line
  0x1f576, // Comment to prevent autoformat, this keeps each on each line
  0x1f97d, // Comment to prevent autoformat, this keeps each on each line
  0x1f97c, // Comment to prevent autoformat, this keeps each on each line
  0x1f9ba, // Comment to prevent autoformat, this keeps each on each line
  0x1f454, // Comment to prevent autoformat, this keeps each on each line
  0x1f455, // Comment to prevent autoformat, this keeps each on each line
  0x1f456, // Comment to prevent autoformat, this keeps each on each line
  0x1f9e3, // Comment to prevent autoformat, this keeps each on each line
  0x1f9e4, // Comment to prevent autoformat, this keeps each on each line
  0x1f9e5, // Comment to prevent autoformat, this keeps each on each line
  0x1f9e6, // Comment to prevent autoformat, this keeps each on each line
  0x1f457, // Comment to prevent autoformat, this keeps each on each line
  0x1f458, // Comment to prevent autoformat, this keeps each on each line
  0x1f97b, // Comment to prevent autoformat, this keeps each on each line
  0x1fa71, // Comment to prevent autoformat, this keeps each on each line
  0x1fa72, // Comment to prevent autoformat, this keeps each on each line
  0x1fa73, // Comment to prevent autoformat, this keeps each on each line
  0x1f459, // Comment to prevent autoformat, this keeps each on each line
  0x1f45a, // Comment to prevent autoformat, this keeps each on each line
  0x1f45b, // Comment to prevent autoformat, this keeps each on each line
  0x1f45c, // Comment to prevent autoformat, this keeps each on each line
  0x1f45d, // Comment to prevent autoformat, this keeps each on each line
  0x1f6cd, // Comment to prevent autoformat, this keeps each on each line
  0x1f392, // Comment to prevent autoformat, this keeps each on each line
  0x1fa74, // Comment to prevent autoformat, this keeps each on each line
  0x1f45e, // Comment to prevent autoformat, this keeps each on each line
  0x1f45f, // Comment to prevent autoformat, this keeps each on each line
  0x1f97e, // Comment to prevent autoformat, this keeps each on each line
  0x1f97f, // Comment to prevent autoformat, this keeps each on each line
  0x1f460, // Comment to prevent autoformat, this keeps each on each line
  0x1f461, // Comment to prevent autoformat, this keeps each on each line
  0x1fa70, // Comment to prevent autoformat, this keeps each on each line
  0x1f462, // Comment to prevent autoformat, this keeps each on each line
  0x1f451, // Comment to prevent autoformat, this keeps each on each line
  0x1f452, // Comment to prevent autoformat, this keeps each on each line
  0x1f3a9, // Comment to prevent autoformat, this keeps each on each line
  0x1f393, // Comment to prevent autoformat, this keeps each on each line
  0x1f9e2, // Comment to prevent autoformat, this keeps each on each line
  0x1fa96, // Comment to prevent autoformat, this keeps each on each line
  0x26d1, // Comment to prevent autoformat, this keeps each on each line
  0x1f4ff, // Comment to prevent autoformat, this keeps each on each line
  0x1f484, // Comment to prevent autoformat, this keeps each on each line
  0x1f48d, // Comment to prevent autoformat, this keeps each on each line
  0x1f48e, // Comment to prevent autoformat, this keeps each on each line
  0x1f507, // Comment to prevent autoformat, this keeps each on each line
  0x1f508, // Comment to prevent autoformat, this keeps each on each line
  0x1f509, // Comment to prevent autoformat, this keeps each on each line
  0x1f50a, // Comment to prevent autoformat, this keeps each on each line
  0x1f4e2, // Comment to prevent autoformat, this keeps each on each line
  0x1f4e3, // Comment to prevent autoformat, this keeps each on each line
  0x1f4ef, // Comment to prevent autoformat, this keeps each on each line
  0x1f514, // Comment to prevent autoformat, this keeps each on each line
  0x1f515, // Comment to prevent autoformat, this keeps each on each line
  0x1f3bc, // Comment to prevent autoformat, this keeps each on each line
  0x1f3b5, // Comment to prevent autoformat, this keeps each on each line
  0x1f3b6, // Comment to prevent autoformat, this keeps each on each line
  0x1f399, // Comment to prevent autoformat, this keeps each on each line
  0x1f39a, // Comment to prevent autoformat, this keeps each on each line
  0x1f39b, // Comment to prevent autoformat, this keeps each on each line
  0x1f3a4, // Comment to prevent autoformat, this keeps each on each line
  0x1f3a7, // Comment to prevent autoformat, this keeps each on each line
  0x1f4fb, // Comment to prevent autoformat, this keeps each on each line
  0x1f3b7, // Comment to prevent autoformat, this keeps each on each line
  0x1fa97, // Comment to prevent autoformat, this keeps each on each line
  0x1f3b8, // Comment to prevent autoformat, this keeps each on each line
  0x1f3b9, // Comment to prevent autoformat, this keeps each on each line
  0x1f3ba, // Comment to prevent autoformat, this keeps each on each line
  0x1f3bb, // Comment to prevent autoformat, this keeps each on each line
  0x1fa95, // Comment to prevent autoformat, this keeps each on each line
  0x1f941, // Comment to prevent autoformat, this keeps each on each line
  0x1fa98, // Comment to prevent autoformat, this keeps each on each line
  0x1f4f1, // Comment to prevent autoformat, this keeps each on each line
  0x1f4f2, // Comment to prevent autoformat, this keeps each on each line
  0x260e, // Comment to prevent autoformat, this keeps each on each line
  0x1f4de, // Comment to prevent autoformat, this keeps each on each line
  0x1f4df, // Comment to prevent autoformat, this keeps each on each line
  0x1f4e0, // Comment to prevent autoformat, this keeps each on each line
  0x1f50b, // Comment to prevent autoformat, this keeps each on each line
  0x1faab, // Comment to prevent autoformat, this keeps each on each line
  0x1f50c, // Comment to prevent autoformat, this keeps each on each line
  0x1f4bb, // Comment to prevent autoformat, this keeps each on each line
  0x1f5a5, // Comment to prevent autoformat, this keeps each on each line
  0x1f5a8, // Comment to prevent autoformat, this keeps each on each line
  0x1f5b2, // Comment to prevent autoformat, this keeps each on each line
  0x1f4bd, // Comment to prevent autoformat, this keeps each on each line
  0x1f4be, // Comment to prevent autoformat, this keeps each on each line
  0x1f4bf, // Comment to prevent autoformat, this keeps each on each line
  0x1f4c0, // Comment to prevent autoformat, this keeps each on each line
  0x1f9ee, // Comment to prevent autoformat, this keeps each on each line
  0x1f3a5, // Comment to prevent autoformat, this keeps each on each line
  0x1f39e, // Comment to prevent autoformat, this keeps each on each line
  0x1f4fd, // Comment to prevent autoformat, this keeps each on each line
  0x1f3ac, // Comment to prevent autoformat, this keeps each on each line
  0x1f4fa, // Comment to prevent autoformat, this keeps each on each line
  0x1f4f7, // Comment to prevent autoformat, this keeps each on each line
  0x1f4f8, // Comment to prevent autoformat, this keeps each on each line
  0x1f4f9, // Comment to prevent autoformat, this keeps each on each line
  0x1f4fc, // Comment to prevent autoformat, this keeps each on each line
  0x1f50d, // Comment to prevent autoformat, this keeps each on each line
  0x1f50e, // Comment to prevent autoformat, this keeps each on each line
  0x1f56f, // Comment to prevent autoformat, this keeps each on each line
  0x1f4a1, // Comment to prevent autoformat, this keeps each on each line
  0x1f526, // Comment to prevent autoformat, this keeps each on each line
  0x1f3ee, // Comment to prevent autoformat, this keeps each on each line
  0x1fa94, // Comment to prevent autoformat, this keeps each on each line
  0x1f4d4, // Comment to prevent autoformat, this keeps each on each line
  0x1f4d5, // Comment to prevent autoformat, this keeps each on each line
  0x1f4d6, // Comment to prevent autoformat, this keeps each on each line
  0x1f4d7, // Comment to prevent autoformat, this keeps each on each line
  0x1f4d8, // Comment to prevent autoformat, this keeps each on each line
  0x1f4d9, // Comment to prevent autoformat, this keeps each on each line
  0x1f4da, // Comment to prevent autoformat, this keeps each on each line
  0x1f4d3, // Comment to prevent autoformat, this keeps each on each line
  0x1f4d2, // Comment to prevent autoformat, this keeps each on each line
  0x1f4c3, // Comment to prevent autoformat, this keeps each on each line
  0x1f4dc, // Comment to prevent autoformat, this keeps each on each line
  0x1f4c4, // Comment to prevent autoformat, this keeps each on each line
  0x1f4f0, // Comment to prevent autoformat, this keeps each on each line
  0x1f5de, // Comment to prevent autoformat, this keeps each on each line
  0x1f4d1, // Comment to prevent autoformat, this keeps each on each line
  0x1f516, // Comment to prevent autoformat, this keeps each on each line
  0x1f3f7, // Comment to prevent autoformat, this keeps each on each line
  0x1f4b0, // Comment to prevent autoformat, this keeps each on each line
  0x1fa99, // Comment to prevent autoformat, this keeps each on each line
  0x1f4b4, // Comment to prevent autoformat, this keeps each on each line
  0x1f4b5, // Comment to prevent autoformat, this keeps each on each line
  0x1f4b6, // Comment to prevent autoformat, this keeps each on each line
  0x1f4b7, // Comment to prevent autoformat, this keeps each on each line
  0x1f4b8, // Comment to prevent autoformat, this keeps each on each line
  0x1f4b3, // Comment to prevent autoformat, this keeps each on each line
  0x1f9fe, // Comment to prevent autoformat, this keeps each on each line
  0x1f4b9, // Comment to prevent autoformat, this keeps each on each line
  0x2709, // Comment to prevent autoformat, this keeps each on each line
  0x1f4e7, // Comment to prevent autoformat, this keeps each on each line
  0x1f4e8, // Comment to prevent autoformat, this keeps each on each line
  0x1f4e9, // Comment to prevent autoformat, this keeps each on each line
  0x1f4e4, // Comment to prevent autoformat, this keeps each on each line
  0x1f4e5, // Comment to prevent autoformat, this keeps each on each line
  0x1f4e6, // Comment to prevent autoformat, this keeps each on each line
  0x1f4eb, // Comment to prevent autoformat, this keeps each on each line
  0x1f4ea, // Comment to prevent autoformat, this keeps each on each line
  0x1f4ec, // Comment to prevent autoformat, this keeps each on each line
  0x1f4ed, // Comment to prevent autoformat, this keeps each on each line
  0x1f4ee, // Comment to prevent autoformat, this keeps each on each line
  0x1f5f3, // Comment to prevent autoformat, this keeps each on each line
  0x270f, // Comment to prevent autoformat, this keeps each on each line
  0x2712, // Comment to prevent autoformat, this keeps each on each line
  0x1f58b, // Comment to prevent autoformat, this keeps each on each line
  0x1f58a, // Comment to prevent autoformat, this keeps each on each line
  0x1f58c, // Comment to prevent autoformat, this keeps each on each line
  0x1f58d, // Comment to prevent autoformat, this keeps each on each line
  0x1f4dd, // Comment to prevent autoformat, this keeps each on each line
  0x1f4bc, // Comment to prevent autoformat, this keeps each on each line
  0x1f4c1, // Comment to prevent autoformat, this keeps each on each line
  0x1f4c2, // Comment to prevent autoformat, this keeps each on each line
  0x1f5c2, // Comment to prevent autoformat, this keeps each on each line
  0x1f4c5, // Comment to prevent autoformat, this keeps each on each line
  0x1f4c6, // Comment to prevent autoformat, this keeps each on each line
  0x1f5d2, // Comment to prevent autoformat, this keeps each on each line
  0x1f5d3, // Comment to prevent autoformat, this keeps each on each line
  0x1f4c7, // Comment to prevent autoformat, this keeps each on each line
  0x1f4c8, // Comment to prevent autoformat, this keeps each on each line
  0x1f4c9, // Comment to prevent autoformat, this keeps each on each line
  0x1f4ca, // Comment to prevent autoformat, this keeps each on each line
  0x1f4cb, // Comment to prevent autoformat, this keeps each on each line
  0x1f4cc, // Comment to prevent autoformat, this keeps each on each line
  0x1f4cd, // Comment to prevent autoformat, this keeps each on each line
  0x1f4ce, // Comment to prevent autoformat, this keeps each on each line
  0x1f587, // Comment to prevent autoformat, this keeps each on each line
  0x1f4cf, // Comment to prevent autoformat, this keeps each on each line
  0x1f4d0, // Comment to prevent autoformat, this keeps each on each line
];

export const emojis = emojisHex.map(hex => String.fromCodePoint(hex, 0xFE0F))

const reversed = new Map<string, number>();
emojisHex.forEach((emoji, i) => reversed.set(String.fromCodePoint(emoji), i));
export const emojisReverse = reversed;